#root {
  .map-wrapper {
    width: 40rem;
    height: 30rem;
    
    .seoul-map {
      display: block;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      fill: rgb(165, 154, 154);
      stroke: rgb(218, 216, 216);
      stroke-width: 0.5px;

      .gu:hover {
        stroke: white;
        stroke-width: 2px;
        fill: rgb(128, 122, 122);
        cursor: pointer;
      }

      .labels {
        //라벨에서도 .gu:hover가 동작하도록 labels에서 마우스 이벤트가 동작하지 않도록 함
        pointer-events: none;
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 480px) {
  #root {
    .map-wrapper {
      width: 22rem;
      height: 19rem;

      .seoul-map {
  
        .gu:hover {
        }
  
        .labels {
          font-size: 11px;
        }
      }
    }  
  }
}