$gu-quiz-blue: #1976d2;

#root {
  .game {
    margin-top: 90px;
    position: fixed;
    width: 100%;

    .wrapper {
      // wrapper 높이는 index.css 파일에서
      background-color: black;

      .gu-img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.4;
      }

      .score-container {
        position: absolute;
        padding-top: 20px;
        right: 20px;
        text-align: center;
        z-index: 2;

        .ur-score-is {
          font-size: 1.5rem;
          font-weight: bolder;
          color: white;
        }

        .score {
          font-size: 2.5rem;
          font-weight: bolder;
          color: white;
          cursor: default;
          transition: 0.3s;
        }

        .score:hover {
          transform: scale(1.3);
        }

      }

      .map-icon {
        position: absolute;
        padding-top: 15px;
        left: 15px;
        color: white;
        cursor: pointer;
        z-index: 2;
      }

      .map-icon :hover {
        opacity: 0.8;
      }

      .quiz-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 2;

        .gu-name {
          width: 100%;
          text-align: center;
          font-size: 55px;
          font-weight: bold;
          color: white;
          margin-bottom: 10px;
          opacity: 0.9;
          z-index: inherit;
        }

        .hidden-hint {
          margin-bottom: 20px;
          font-weight: bold;
          color: gray;
          background-color: rgba(122, 122, 122, 0.1);
          z-index: inherit;
        }

        .input-and-result {
          z-index: 3;

          .map-container {
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #root {
    .game {
      .wrapper {
        // wrapper 높이는 index.css 파일에서

        .score-container {
          .ur-score-is {
            font-size: 1rem;
          }
          .score {
            font-size: 1.5rem;
          }
        }

        .quiz-container {
          .gu-name {
            font-size: 35px;
          }

          .answer-container {
            width: 16rem;
          }

          .input-and-result {
            .map-container {
            }
          }
        }
      }
    }
  }
}