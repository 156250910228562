#root .header {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: lightgray;
  z-index: -1;
}

#root .header .container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
