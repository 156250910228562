#root {
  .welcome {
    margin-top: 90px;
    position: fixed;
    width: 100%;

    .wrapper {
      // wrapper 높이는 index.css 파일에서
      background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) 50% 50% / 100%, url(https://media.giphy.com/media/XZg1znDzsQ1tHeqnQu/giphy.gif);
      background-position: center;
      background-size: contain;

      .info-icon {
        position: absolute;
        padding-top: 15px;
        right: 15px;
      }

      .title-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .main-title {
          font-size: 5rem;
          font-weight: bold;
          color: white;
          margin-bottom: 16px;
        }

        .subtitle-container {
          display: flex;
          column-gap: 7px;
          line-height: 26px;  //텍스트 높이 맞추기 위해

          .subtitle {
            font-weight: bold;
            color: gray;
          }
        }

        .start-btn {
          .game-start {
            min-width: 10rem;
            font-size: 1.5rem;
            font-weight: bolder;
            padding: 0.9rem 1.5rem;
            border-radius: 5rem;
            border: 2px solid white;
            margin-top: 25px;
            background-color: transparent;
            color: white;
            cursor: pointer;
            transition: all 0.5s ease 0s;
            animation: 1.5s infinite normal heartbeat;
          }

          .game-start:hover {
            color: black;
            background-color: white;
          }

          @keyframes heartbeat {
            0% {
              transform: scale(1);
              transform-origin: center center;
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
              transform-origin: center center;
            }
          }
        }

      }
    }
  }
}

@media (max-width: 480px) {
  #root {
    .welcome {
      .wrapper {
        // wrapper 높이는 index.css 파일에서

        .title-container {
          .main-title {
            font-size: 45px;
          }

          .subtitle-container {
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
  }
}
