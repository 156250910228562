#root .game {
  margin-top: 90px;
  position: fixed;
  width: 100%;
}

#root .game .wrapper {
  background-color: black;
}

#root .game .wrapper .gu-img {
  position: absolute;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  opacity: 0.4;
}

#root .game .wrapper .score-container {
  position: absolute;
  padding-top: 20px;
  right: 20px;
  text-align: center;
  z-index: 2;
}

#root .game .wrapper .score-container .ur-score-is {
  font-size: 1.5rem;
  font-weight: bolder;
  color: white;
}

#root .game .wrapper .score-container .score {
  font-size: 2.5rem;
  font-weight: bolder;
  color: white;
  cursor: default;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

#root .game .wrapper .score-container .score:hover {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

#root .game .wrapper .map-icon {
  position: absolute;
  padding-top: 15px;
  left: 15px;
  color: white;
  cursor: pointer;
  z-index: 2;
}

#root .game .wrapper .map-icon :hover {
  opacity: 0.8;
}

#root .game .wrapper .quiz-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  z-index: 2;
}

#root .game .wrapper .quiz-container .gu-name {
  width: 100%;
  text-align: center;
  font-size: 55px;
  font-weight: bold;
  color: white;
  margin-bottom: 10px;
  opacity: 0.9;
  z-index: inherit;
}

#root .game .wrapper .quiz-container .hidden-hint {
  margin-bottom: 20px;
  font-weight: bold;
  color: gray;
  background-color: rgba(122, 122, 122, 0.1);
  z-index: inherit;
}

#root .game .wrapper .quiz-container .input-and-result {
  z-index: 3;
}

@media (max-width: 480px) {
  #root .game .wrapper .score-container .ur-score-is {
    font-size: 1rem;
  }
  #root .game .wrapper .score-container .score {
    font-size: 1.5rem;
  }
  #root .game .wrapper .quiz-container .gu-name {
    font-size: 35px;
  }
  #root .game .wrapper .quiz-container .answer-container {
    width: 16rem;
  }
}
