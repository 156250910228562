#root .result {
  margin-top: 90px;
  position: fixed;
  width: 100%;
}

#root .result .wrapper {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.7)), to(rgba(0, 0, 0, 0.7))) 50% 50%/100%, url(https://media.giphy.com/media/l1J3UU9QBHDaGr052/giphy.gif);
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) 50% 50%/100%, url(https://media.giphy.com/media/l1J3UU9QBHDaGr052/giphy.gif);
  background-position: center;
  background-size: cover;
}

#root .result .wrapper .result-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#root .result .wrapper .result-container .score-container .tell-score {
  font-size: 2.5rem;
  color: white;
  font-weight: bold;
  text-align: center;
}

#root .result .wrapper .result-container .score-container .score {
  font-size: 10rem;
  color: goldenrod;
  text-align: center;
}

#root .result .wrapper .result-container .score-container .msg {
  font-size: 1.2rem;
  font-weight: bolder;
  color: lightgray;
  margin-bottom: 1rem;
  text-align: center;
}

#root .result .wrapper .result-container .goback-btn .go-welcome {
  min-width: 10rem;
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 0.9rem 1.5rem;
  border-radius: 5rem;
  border: 2px solid white;
  margin: 0.5rem;
  background-color: transparent;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-animation: 1.5s infinite normal heartbeat;
          animation: 1.5s infinite normal heartbeat;
}

#root .result .wrapper .result-container .goback-btn .go-welcome:hover {
  color: black;
  background-color: white;
}

@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}

@keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}

@media (max-width: 480px) {
  #root .result .wrapper .result-container .score-container .tell-score {
    font-size: 25px;
  }
  #root .result .wrapper .result-container .score-container .score {
    font-size: 100px;
  }
}
