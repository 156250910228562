#root .footer {
  width: 100%;
  height: 10vh;
  position: fixed;
  bottom: 0;
  background-color: lightgray;
}

#root .footer .container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
