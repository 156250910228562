#root {
  .result {
    margin-top: 90px;
    position: fixed;
    width: 100%;

    .wrapper {
      // wrapper 높이는 index.css 파일에서
      background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)) 50% 50% / 100%, url(https://media.giphy.com/media/l1J3UU9QBHDaGr052/giphy.gif);
      background-position: center;
      background-size: cover;

      .result-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .score-container {

          .tell-score {
            font-size: 2.5rem;
            color: white;
            font-weight: bold;
            text-align: center;
          }

          .score {
            font-size: 10rem;
            color: goldenrod;
            text-align: center;
          }

          .msg {
            font-size: 1.2rem;
            font-weight: bolder;
            color: lightgray;
            margin-bottom: 1rem;
            text-align: center;
          }

        }

        .goback-btn {

          .go-welcome {
            min-width: 10rem;
            font-size: 1.5rem;
            font-weight: bolder;
            padding: 0.9rem 1.5rem;
            border-radius: 5rem;
            border: 2px solid white;
            margin: 0.5rem;
            background-color: transparent;
            color: white;
            cursor: pointer;
            transition: all 0.5s ease 0s;
            animation: 1.5s infinite normal heartbeat;
          }

          .go-welcome:hover {
            color: black;
            background-color: white;
          }

          @keyframes heartbeat {
            0% {
              transform: scale(1);
              transform-origin: center center;
            }
            50% {
              transform: scale(1.05);
            }
            100% {
              transform: scale(1);
              transform-origin: center center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  #root {
    .result {
      .wrapper {
        // wrapper 높이는 index.css 파일에서

        .result-container {
          .score-container {
            .tell-score {
              font-size: 25px;
            }

            .score {
              font-size: 100px;
            }
          }
        }
      }
    }
  }
}