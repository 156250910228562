#root .welcome {
  margin-top: 90px;
  position: fixed;
  width: 100%;
}

#root .welcome .wrapper {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))) 50% 50%/100%, url(https://media.giphy.com/media/XZg1znDzsQ1tHeqnQu/giphy.gif);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) 50% 50%/100%, url(https://media.giphy.com/media/XZg1znDzsQ1tHeqnQu/giphy.gif);
  background-position: center;
  background-size: contain;
}

#root .welcome .wrapper .info-icon {
  position: absolute;
  padding-top: 15px;
  right: 15px;
}

#root .welcome .wrapper .title-container {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

#root .welcome .wrapper .title-container .main-title {
  font-size: 5rem;
  font-weight: bold;
  color: white;
  margin-bottom: 16px;
}

#root .welcome .wrapper .title-container .subtitle-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 7px;
          column-gap: 7px;
  line-height: 26px;
}

#root .welcome .wrapper .title-container .subtitle-container .subtitle {
  font-weight: bold;
  color: gray;
}

#root .welcome .wrapper .title-container .start-btn .game-start {
  min-width: 10rem;
  font-size: 1.5rem;
  font-weight: bolder;
  padding: 0.9rem 1.5rem;
  border-radius: 5rem;
  border: 2px solid white;
  margin-top: 25px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-animation: 1.5s infinite normal heartbeat;
          animation: 1.5s infinite normal heartbeat;
}

#root .welcome .wrapper .title-container .start-btn .game-start:hover {
  color: black;
  background-color: white;
}

@-webkit-keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}

@keyframes heartbeat {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
}

@media (max-width: 480px) {
  #root .welcome .wrapper .title-container .main-title {
    font-size: 45px;
  }
  #root .welcome .wrapper .title-container .subtitle-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
}
