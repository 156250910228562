#root .map-wrapper {
  width: 40rem;
  height: 30rem;
}

#root .map-wrapper .seoul-map {
  display: block;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  fill: #a59a9a;
  stroke: #dad8d8;
  stroke-width: 0.5px;
}

#root .map-wrapper .seoul-map .gu:hover {
  stroke: white;
  stroke-width: 2px;
  fill: #807a7a;
  cursor: pointer;
}

#root .map-wrapper .seoul-map .labels {
  pointer-events: none;
  font-size: 15px;
}

@media (max-width: 480px) {
  #root .map-wrapper {
    width: 22rem;
    height: 19rem;
  }
  #root .map-wrapper .seoul-map .labels {
    font-size: 11px;
  }
}
