#root {
  .footer {
    width: 100%;
    height: 10vh;
    position: fixed;
    bottom: 0;
    background-color: lightgray;

    .container {
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}