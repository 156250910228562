#root {
  .header {
    width: 100%;
    position: fixed;
    top: 0;
    background-color: lightgray;
    z-index: -1;

    .container {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }
}